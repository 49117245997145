import {
  VERIFY_PIN_RESPONSE,
  SUBMIT_RESPONSE,
  GET_FLAG_RESPONSE,
  GET_IP_RESPONSE,
} from './login.types'

const INITIAL_STATE = {
  pinResponse: {},
  submitResponse: {},
  flagResponse: {},
  ipResponse: {},
}

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERIFY_PIN_RESPONSE: {
      return { ...state, ...{ pinResponse: action.payload } }
    }
    case SUBMIT_RESPONSE: {
      return { ...state, ...{ submitResponse: action.payload } }
    }
    case GET_FLAG_RESPONSE: {
      return { ...state, ...{ flagResponse: action.payload } }
    }
    case GET_IP_RESPONSE: {
      return { ...state, ...{ ipResponse: action.payload } }
    }
    default:
      return state
  }
}

export default loginReducer

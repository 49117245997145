import React from 'react'
import { PrivateRoute } from 'Utils/private-route'
import Loader from './BaseComponents/Loader'
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'

const Reminder = React.lazy(() => import('./Containers/Reminder'))
const Success = React.lazy(() => import('./Containers/Success'))
const Error = React.lazy(() => import('./Containers/Error'))

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/loading" component={Loader} />
            <PrivateRoute authed={true} path="/success" component={Success} />
            <PrivateRoute path="/error" component={Error} />
            <PrivateRoute path="/" component={Reminder} />
            <Redirect from="/" to="/error" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App

import { combineEpics } from 'redux-observable'
import loginEpic from './Login/login.epic'

const rootEpic = combineEpics(
  loginEpic.verifyPin,
  loginEpic.submit,
  loginEpic.getFlag,
  loginEpic.getIpApi,
)

export default rootEpic

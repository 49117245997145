import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './Redux/store'
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
/**
 * @file Index.jsx
 * @fileoverview React starting main file, it call App.jsx for manage all other react function and also call Provider for manage redux storage
 * @author Anuragh KP <kpanuragh@gmail.com>
 *
 */

const theme = createTheme({
  palette: {
    primary: {
      main: '#0D9797', // Teal color which matches the companies theme
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import {
  VERIFY_PIN,
  VERIFY_PIN_RESPONSE,
  SUBMIT,
  SUBMIT_RESPONSE,
  GET_FLAG,
  GET_FLAG_RESPONSE,
  GET_IP,
  GET_IP_RESPONSE,
} from './login.types'

export const checkPin = (pinData) => ({
  type: VERIFY_PIN,
  payload: pinData,
})

export const checkPinResponse = (pinResponse) => ({
  type: VERIFY_PIN_RESPONSE,
  payload: pinResponse,
})

export const submit = (data) => ({
  type: SUBMIT,
  payload: data,
})

export const submitResponse = (response) => ({
  type: SUBMIT_RESPONSE,
  payload: response,
})

export const getFlag = (data) => ({
  type: GET_FLAG,
  payload: data,
})

export const getFlagResponse = (response) => ({
  type: GET_FLAG_RESPONSE,
  payload: response,
})

export const getIp = (data) => ({
  type: GET_IP,
  payload: data,
})

export const getIpResponse = (response) => ({
  type: GET_IP_RESPONSE,
  payload: response,
})

import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { VERIFY_PIN, SUBMIT, GET_FLAG, GET_IP ,REQUEST_CANCEL } from './login.types'
import {
  checkPinResponse,
  getFlagResponse,
  submitResponse,
  getIpResponse,
} from './login.actions'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
const baseUrl = environment.baseUrl

const loginEpic = {}

loginEpic.verifyPin = (action$) =>
  action$.pipe(
    ofType(VERIFY_PIN),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Login_verifyPin}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => checkPinResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.submit = (action$) =>
  action$.pipe(
    ofType(SUBMIT),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Login_submit}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => submitResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.getFlag = (action$) =>
  action$.pipe(
    ofType(GET_FLAG),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Login_getFlag}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getFlagResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.getIpApi = (action$) =>
  action$.pipe(
    ofType(GET_IP),
    switchMap(() =>
      ajax({
        url: `https://geolocation-db.com/json/`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getIpResponse(response.response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default loginEpic
